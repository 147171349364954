import Vue from "vue";
import App from "./App.vue";
import vuetify from "@/plugins/vuetify";
import i18n from "@/plugins/vue-i18n";
import Celebrate from "@/plugins/celebrate";
import "@/plugins/vee-validate";
import "@/plugins/vega";
import "@/plugins/celebrate";
import "@/plugins/dialog";
import "@/plugins/tooltip";
import "@/plugins/raiError";

import store from "@/store";
import { createRouter } from "./router";
import { createSounds } from "./sounds";
import pusher from "@/pusher-config";
import { createApolloProvider } from "@/vue-apollo-config";
import { setupRaiPos } from "@/utils/setup";
import { removeRaiLoading } from "@/utils/preloader";

import initializeInterceptor from "@/utils/interceptor";
import "@/registerServiceWorker";
import titleMixin from "@/mixins/titleMixin";
//const wysiwyg = () => import(/* webpackChunkName: "wysiwyg" */ "vue-wysiwyg");
import wysiwyg from "vue-wysiwyg";

import { AllDirectives } from "@/directives";
import { AllFilters } from "@/filters";
import { each } from "lodash";

// We use an async function here
//  so we can +await+ the auth/refresh call.
// Otherwise, we'd have a race condition where the
//  app tries to initialize while auth is being
//  refreshed, and the user could easily wind up
//  in TeamMode.
async function createApp() {
  Vue.config.productionTip = false;
  if (process.env.NODE_ENV === "development") {
    Vue.config.devtools = true;
    Vue.config.performance = true;
    import("graphql-tag").then(({ default: gql }) => {
      window.$gql = gql;
    });
  }

  Vue.use(Celebrate);
  Vue.use(wysiwyg);
  // Global page loading indicator
  Vue.prototype.isLoading = true;
  Vue.prototype.isOnline = window.navigator.onLine || true;
  Vue.prototype.$pusher = pusher;
  window.$pusher = pusher;
  Vue.mixin(titleMixin);

  await store.dispatch("auth/refresh");

  // setup $raiPos first
  // // --------------------
  // // -- SETUP RAI POS API
  // // --------------------
  // TODO: return raiPos for use in createSounds()
  setupRaiPos({ window, vue: Vue, store });

  const raiAudio = createSounds(Vue.prototype.$raiPos);
  Vue.prototype.$sounds = raiAudio;
  window.raiAudio = raiAudio;

  // Lock screen if necessary
  // lockScreenIfPos(store);  // No longer needed

  // wait to create the router until after auth is refreshed
  const router = createRouter();

  initializeInterceptor(undefined, Vue);
  const apolloProvider = createApolloProvider({
    pusherClient: pusher,
    // uncomment to disable batching
    // disableBatching: true,
  });

  // removes loading indicator
  // SplashView adds it back
  router.onReady(() => {
    removeRaiLoading();
  });

  each(AllDirectives, (func, name) => {
    Vue.directive(name, func);
  });

  // We do it like this in preparation for vue 3.0
  Vue.prototype.$filters = AllFilters;

  const v = new Vue({
    i18n,
    router,
    store,
    vuetify,
    apolloProvider,
    render: (h) => h(App),
  }).$mount("#app");

  const updateSystemTheme = (prefersDarkTheme) => {
    v.$store.state.ui.systemDark = prefersDarkTheme.matches;
    if (v.$store.state.ui.theme === "system") {
      v.$vuetify.theme.dark = prefersDarkTheme.matches;
    }
  };

  // set initial dark mode
  const prefersDarkTheme = window.matchMedia("(prefers-color-scheme: dark)");
  updateSystemTheme(prefersDarkTheme);

  prefersDarkTheme.addEventListener("change", (e) => {
    updateSystemTheme(e);
  });
}

createApp();
